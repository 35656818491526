<template>
  <th-modal
    name="addon-modal"
    width="700px"
    :title="$t('pages.product_addon_groups.edit.items.modal.title')"
    data-testid="addon-modal"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :xs="24" :sm="9">
          <el-form-item
            :label="$t('pages.product_addon_groups.edit.general.name.label')"
            prop="name"
          >
            <el-input id="name" v-model="form.name" />
          </el-form-item>
        </el-col>

        <!-- Price change -->
        <el-col :xs="24" :sm="15" class="mb-2">
          <el-row
            v-for="(price, index) in form.price_change"
            :key="index"
            :gutter="20"
          >
            <!-- Currency -->
            <el-col v-if="currencies.length > 1" :span="8">
              <el-form-item
                :label="index === 0 ? $t('common.forms.labels.currency') : ''"
                prop="currency"
                class="mb-2"
              >
                <th-currency-select
                  id="currency"
                  v-model="price.currency"
                  add-class="w-full"
                  :filtered-currencies="usedCurrencies"
                  :allowed-currencies="currencies"
                  :disabled="currencies.length < 2"
                />
              </el-form-item>
            </el-col>

            <!-- Price -->
            <el-col :span="8">
              <el-form-item
                :label="
                  index === 0
                    ? $t('pages.product_addon_groups.edit.items.table.price')
                    : ''
                "
                prop="amount"
                class="mb-2"
              >
                <th-currency-input
                  id="amount"
                  v-model="price.amount"
                  :currency="price.currency"
                />
              </el-form-item>
            </el-col>

            <el-col :span="8" class="flex items-center">
              <!-- Delete -->
              <el-button
                v-if="currencies.length > 1"
                plain
                icon="Delete"
                class="-ml-3 el-button--text-icon"
                :class="{ 'mt-5': index === 0 }"
                @click="deletePrice(index)"
              />

              <!-- Add -->
              <el-button
                v-if="index === 0 && avaliableCurrencies.length > 0"
                plain
                type="primary"
                icon="Plus"
                class="px-3 h-10"
                :class="{ 'mt-5': index === 0 }"
                @click="addPrice"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Allow quantity edit -->
        <el-col :xs="12" :sm="9">
          <el-form-item prop="allow_quantity_edit">
            <div class="flex">
              <div class="mr-2 mt-6">
                <th-popover
                  :text="
                    $t(
                      'pages.product_addon_groups.edit.items.table.allow_quantity_edit.description'
                    )
                  "
                  placement="top"
                />
              </div>
              <el-switch
                id="allow_quantity_edit"
                v-model="form.allow_quantity_edit"
                class="mt-5 h-10"
                :active-text="
                  $t(
                    'pages.product_addon_groups.edit.items.table.allow_quantity_edit.text'
                  )
                "
                @change="handleAllowQuantityEditChange"
              />
            </div>
          </el-form-item>
        </el-col>

        <!-- Max quantity -->
        <el-col :xs="12" :sm="5">
          <el-form-item
            :label="
              $t('pages.product_addon_groups.edit.items.table.max-quantity')
            "
            prop="max_quantity"
          >
            <th-number-input
              id="max_quantity"
              v-model="form.max_quantity"
              :disabled="!form.allow_quantity_edit"
              :locale="$i18n.locale"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Affect stock -->
        <el-col :xs="24" :sm="9">
          <el-form-item prop="affect_stock">
            <div class="flex">
              <div class="mr-2 mt-6">
                <th-popover
                  :text="
                    $t(
                      'pages.product_addon_groups.edit.items.table.affect_stock.description'
                    )
                  "
                  placement="top"
                />
              </div>
              <el-switch
                id="affect_stock"
                v-model="form.affect_stock"
                class="mt-5 h-10"
                :active-text="
                  $t(
                    'pages.product_addon_groups.edit.items.table.affect_stock.text'
                  )
                "
                @change="handleAffectStockChange"
              />
            </div>
          </el-form-item>
        </el-col>

        <!-- Quantity -->
        <el-col :xs="8" :sm="5">
          <el-form-item
            :label="
              $t('pages.product_addon_groups.edit.items.table.stock-quantity')
            "
            prop="stock_quantity"
          >
            <th-number-input
              id="stock_quantity"
              v-model="form.stock_quantity"
              :disabled="!form.affect_stock"
              :precision="3"
              :locale="$i18n.locale"
            />
          </el-form-item>
        </el-col>

        <!-- Product -->
        <el-col :xs="16" :sm="10">
          <el-form-item
            :label="$t('pages.product_addon_groups.edit.items.table.product')"
            prop="product"
          >
            <remote-search-select
              id="product"
              v-model="form.product"
              class="w-full"
              popper-append-to-body
              :disabled="!form.affect_stock"
              :modify-query="
                (q) => ({
                  query: {
                    limit: 50,
                    deleted: false,
                    q
                  }
                })
              "
              resource="products"
              fetch-handler="getAll"
              :computed-fields="['custom_id', 'name']"
              @resource-set="handleSetProduct"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <!-- Add to invoice -->
      <el-checkbox
        v-model="form.add_to_cart"
        :disabled="!form.product"
        :label="
          $t('pages.product_addon_groups.edit.items.table.add-to-invoice')
        "
      />

      <div class="flex-grow" />

      <!-- Cancel -->
      <el-button plain @click="handleClose">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Save -->
      <el-button type="primary" @click="handleSave">
        {{ $t('common.interactions.buttons.save') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies'
    }),

    usedCurrencies() {
      return this.form.price_change.map((p) => p.currency)
    },

    avaliableCurrencies() {
      return this.currencies.filter(
        (item) => !this.usedCurrencies.includes(item)
      )
    },

    rules() {
      const rules = {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            max: 512,
            message: this.$t('common.forms.rules.max_length', { length: 512 }),
            trigger: 'blur'
          }
        ]
      }

      if (this.form.allow_quantity_edit) {
        rules.max_quantity = [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }

      if (this.form.affect_stock) {
        rules.stock_quantity = [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
        rules.product = [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }
      return rules
    },

    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  mounted() {
    if (this.form.price_change.length === 0) this.addPrice()
  },

  methods: {
    handleAllowQuantityEditChange() {
      if (!this.form.allow_quantity_edit) {
        this.form.max_quantity = 1
      }
    },

    handleAffectStockChange() {
      if (!this.form.affect_stock) {
        this.form.stock_quantity = 0
        this.form.product = null
        this.form.add_to_cart = false
      }
    },

    handleSetProduct(product) {
      this.form.product_name = product ? product.name : null
    },

    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    addPrice() {
      this.form.price_change.push({
        currency:
          this.form.price_change.length === 0
            ? this.defaultCurrency
            : this.avaliableCurrencies[0],
        amount: 0
      })
    },

    deletePrice(index) {
      this.form.price_change.splice(index, 1)
      if (this.form.price_change.length === 0) this.addPrice()
    },

    handleClose() {
      this.$emit('close')
    },

    async handleSave() {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          title: this.$t('common.forms.warning.invalid_input.title'),
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      this.$emit('save', { ...this.form })
    }
  }
}
</script>
