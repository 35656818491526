<template>
  <th-wrapper
    :title="$t('pages.product_addon_groups.edit.items.title')"
    :subtitle="$t('pages.product_addon_groups.edit.items.subtitle')"
    header-class="bg-th-primary-light"
    collapsable
    body-class="p-0"
    data-testid="add-ons"
  >
    <template #header-additional>
      <filter-header
        :apply-margin="false"
        :resource="false"
        show-search
        class="pt-4 pb-6"
        @search-filter="filterChanged"
      >
        <!-- New -->
        <div class="w-full flex flex-row-reverse">
          <el-button
            type="primary"
            class="flex items-center h-th-action-button-height"
            @click="handleAdd"
          >
            <svgicon
              :src="require('@/assets/icons/th-icon-plus.svg')"
              class="mr-2"
              :style="{ height: '20px', width: '20px' }"
            />
            <span>{{ $t('common.components.th_datatable.addNew') }}</span>
          </el-button>
        </div>
      </filter-header>
    </template>

    <!-- Body -->
    <th-table
      :data="filteredAddons"
      :columns="columns"
      configuration-name="settings.headerFilters.product-addon-group-items"
      can-click-row
      can-delete-row
      data-testid="addons-table"
      @row-clicked="handleEdit"
      @row-delete="handleDelete"
    />

    <!-- Modal addon -->
    <addon-modal
      v-model="selectedAddon"
      @close="closeAddon"
      @save="handleAddon"
    />
  </th-wrapper>
</template>

<script>
import ThTable from '@/components/table'
import FilterHeader from '@/components/filter-header'
import AddonModal from './items/addon-modal.vue'
import deepClone from 'clone-deep'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'

export default {
  components: {
    ThTable,
    FilterHeader,
    AddonModal
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      filters: {},
      selectedAddon: this.addonInitialData(),
      columns: [
        {
          label: this.$t('common.titles.name'),
          key: 'name',
          visible: true,
          formatter: (value) => value.name || '--'
        },
        {
          label: this.$t('pages.product_addon_groups.edit.items.table.price'),
          key: 'price',
          visible: true,
          formatter: (value) => {
            const price = safeGet(value, 'price_change.0')
            if (!price) return '--'
            return this.$formatCurrency(price.amount, price.currency)
          }
        },
        {
          label: this.$t('pages.product_addon_groups.edit.items.table.product'),
          key: 'product',
          visible: true,
          formatter: (value) => value.product_name || '--'
        },
        {
          label: this.$t(
            'pages.product_addon_groups.edit.items.table.stock-quantity'
          ),
          key: 'stock_quantity',
          visible: true,
          formatter: (value) => value.stock_quantity || '--'
        },
        {
          label: this.$t(
            'pages.product_addon_groups.edit.items.table.max-quantity'
          ),
          key: 'max-quantity',
          visible: true,
          formatter: (value) => value.max_quantity || '--'
        },
        {
          label: this.$t(
            'pages.product_addon_groups.edit.items.table.allow_quantity_edit.text'
          ),
          key: 'allow-quantity-edit',
          visible: true,
          formatter: (value) => this.showBoolean(value.allow_quantity_edit)
        },
        {
          label: this.$t(
            'pages.product_addon_groups.edit.items.table.add-to-invoice'
          ),
          key: 'add_to_cart',
          visible: true,
          formatter: (value) => this.showBoolean(value.add_to_cart)
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),

    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    filteredAddons() {
      // Filter out marked for delete and search by name and product name
      const q = safeGet(this.filters, 'q', '').toLowerCase()
      return this.form.addons.filter(
        (a) =>
          !a._delete &&
          ((q ? a.name.toLowerCase().includes(q) : true) ||
            (q ? (a.product_name || '').toLowerCase().includes(q) : true))
      )
    }
  },

  methods: {
    filterChanged(filters) {
      this.filters = filters
    },

    handleAdd() {
      this.selectedAddon = this.addonInitialData()
      this.$thModal.show('addon-modal')
    },

    handleEdit(addon) {
      this.selectedAddon = deepClone(addon)
      this.$thModal.show('addon-modal')
    },

    closeAddon() {
      this.selectedAddon = this.addonInitialData()
      this.$thModal.hide('addon-modal')
    },

    handleDelete(addon) {
      addon._delete = true
    },

    handleAddon(addon) {
      const i = this.form.addons.findIndex((a) => a._key === addon._key)
      if (i < 0) {
        this.form.addons.push(addon)
      } else {
        addon._update = true
        this.form.addons[i] = addon
      }
      this.$thModal.hide('addon-modal')
      this.selectedAddon = this.addonInitialData()
    },

    addonInitialData() {
      return {
        name: null,
        product: null,
        price_change: [
          {
            currency: this.defaultCurrency,
            amount: 0
          }
        ],
        stock_quantity: 0,
        add_to_cart: false,
        allow_quantity_edit: false,
        affect_stock: false,
        max_quantity: 1,
        _delete: false,
        _update: false,
        _key: Array(8)
          .fill()
          .map((_) => String.fromCharCode(33 + Math.random() * (127 - 33)))
          .join('')
      }
    },

    showBoolean(v) {
      return v
        ? this.$t('common.interactions.buttons.yes')
        : this.$t('common.interactions.buttons.no')
    }
  }
}
</script>
