<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      v-loading="loading"
      do-route
      do-route-filters
      no-meta-check
      resource="productAddonGroups"
      route-base="/products/addon-groups"
      show-search-filter
      sortable
      :search-filters="filtersList"
      :buttons="computedButtons"
      :headers="headers"
      :resource-query="{ query: { deleted: false } }"
      :show-operations="false"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('pages.product_addon_groups.all.title')
    }
  },

  data() {
    return {
      loading: false,
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          truncate: true
        },
        {
          field: 'options',
          label: this.$t('pages.pricebooks.entries.all.table.options'),
          fallback: '-',
          formatter: (row) => row.addons.map((a) => a.name).join(' | ')
        }
      ],
      filtersList: [
        {
          name: 'multiselect',
          type: 'select',
          label: this.$t(
            'pages.product_addon_groups.all.filters.multiselect.title'
          ),
          options: [
            {
              label: this.$t('common.interactions.buttons.yes'),
              value: 'true'
            },
            {
              label: this.$t('common.interactions.buttons.no'),
              value: 'false'
            }
          ]
        },
        {
          name: 'skippable',
          type: 'select',
          label: this.$t(
            'pages.product_addon_groups.all.filters.skippable.title'
          ),
          options: [
            {
              label: this.$t('common.interactions.buttons.yes'),
              value: 'true'
            },
            {
              label: this.$t('common.interactions.buttons.no'),
              value: 'false'
            }
          ]
        },
        {
          name: 'active',
          type: 'select',
          label: this.$t('pages.products.all.filters.active.title'),
          options: [
            {
              label: this.$t('common.interactions.buttons.yes'),
              value: 'true'
            },
            {
              label: this.$t('common.interactions.buttons.no'),
              value: 'false'
            }
          ]
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:addon_groups:create']
        }
      ]
    }
  },

  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },

  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.product_addon_groups.all.title')
        })
      })
    }
  }
}
</script>
