<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    body-class="p-8 pb-3"
    data-testid="general"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :sm="6" :lg="6">
          <el-form-item
            :label="$t('pages.product_addon_groups.edit.general.name.label')"
            prop="name"
          >
            <el-input id="name" v-model="form.name" />
          </el-form-item>
        </el-col>

        <!-- Maximum Selected Add-Ons -->
        <el-col v-show="form.multiselect" :sm="4" :lg="4">
          <el-form-item
            :label="
              $t('pages.product_addon_groups.edit.general.max_selected.label')
            "
            prop="max_selected"
          >
            <th-number-input
              id="max_selected"
              v-model="form.max_selected"
              :locale="$i18n.locale"
            />
          </el-form-item>
        </el-col>

        <el-col :sm="4" :lg="4" class="flex">
          <!-- Allow skipping -->
          <el-form-item class="mt-5 h-10 mr-4 xs:mt-0" prop="skippable">
            <el-checkbox
              id="skippable"
              v-model="form.skippable"
              :label="
                $t('pages.product_addon_groups.edit.general.skippable.label')
              "
            />
          </el-form-item>

          <!-- Multi-Select -->
          <el-form-item class="mt-5 h-10 xs:mt-0" prop="multiselect">
            <el-checkbox
              id="multiselect"
              v-model="form.multiselect"
              :label="
                $t('pages.product_addon_groups.edit.general.multiselect.label')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    rules() {
      const rules = {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 64
            }),
            trigger: 'blur'
          }
        ]
      }

      if (this.form.multiselect) {
        rules.max_selected = [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }

      return rules
    }
  },

  watch: {
    'form.multiselect'(isMultiselect) {
      if (!isMultiselect) {
        this.form.max_selected = null
      }
    }
  },

  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
