<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <product-addon-group-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import ProductAddonGroupForm from './components/form.vue'

export default {
  name: 'ProductAddonsGroupEdit',

  components: {
    ProductAddonGroupForm
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },

    actions() {
      return {
        permissionPrefix: 'products:addon_groups',
        returnedPath: { name: 'products-addon-groups-list' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },

    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
