<template>
  <div class="m-8">
    <!-- General -->
    <product-addon-groups-general
      ref="general"
      v-model="form"
      v-loading="loading"
    />

    <!-- Items -->
    <product-addon-groups-items
      ref="items"
      v-model="form"
      v-loading="loading || loadingAddons"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeHandleableBody } from '@/utils/objects'
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import ProductAddonGroupsGeneral from './product-addon-groups-general'
import ProductAddonGroupsItems from './product-addon-groups-items'

const InitialAddonData = {
  name: null,
  product: null,
  price_change: [],
  stock_quantity: null,
  add_to_cart: false,
  allow_quantity_edit: false,
  max_quantity: null
}

function genInitialData() {
  return {
    name: null,
    addons: [],
    max_selected: null,
    multiselect: false,
    skippable: false,
    active: true
  }
}

export default {
  components: {
    ProductAddonGroupsGeneral,
    ProductAddonGroupsItems
  },

  data() {
    return {
      loading: false,
      loadingAddons: false,
      form: genInitialData()
    }
  },

  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),

    addonGroupId() {
      return this.$route.params.id
    },

    isNew() {
      return !this.$route.params.id
    }
  },

  async mounted() {
    if (!this.isNew) await this.fetch()
  },

  methods: {
    async fetchAddons() {
      try {
        this.loadingAddons = true
        const { data = {} } = await th
          .productAddons()
          .getAll({ addon_group: this.addonGroupId, deleted: false })
        data.forEach((d) => {
          d.affect_stock = d.stock_quantity > 0 || d.productAddons
          d._update = false // Used for easier updating of addons
          d._delete = false // Used for deleting already created addons
          d._key = Array(8)
            .fill()
            .map((_) => String.fromCharCode(33 + Math.random() * (127 - 33)))
            .join('') // Used to identify addon in table when deleting or editing
        })
        this.form.addons = data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.product_addon_groups.edit.messages.addons_fetch_error'
          )
        })
      } finally {
        this.loadingAddons = false
      }
    },

    async handleAddons(productGroupId) {
      this.loadingAddons = true
      for (let i = 0; i < this.form.addons.length; i++) {
        const addon = this.form.addons[i]
        if (!addon.id && !addon._delete)
          await this.createAddon(addon, productGroupId)
        else if (addon._delete) await this.deleteAddon(addon)
        else if (addon._update) await this.alterAddon(addon, productGroupId)
      }
      this.loadingAddons = false
    },

    async createAddon(addon, productGroupId) {
      const payload = pick(addon, Object.keys(InitialAddonData))
      payload.addon_group = productGroupId

      try {
        await th.productAddons().create(payload)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.forms.error.create_fail.message', {
            resource: this.$t('common.resource.product_addon.singular')
          })
        })
      }
    },

    async alterAddon(addon) {
      const payload = pick(addon, Object.keys(InitialAddonData))
      payload.addon_group = this.addonGroupId

      try {
        await th.productAddons().put(addon.id, payload)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.forms.error.alter_fail.message', {
            resource: this.$t('common.resource.product_addon.singular')
          })
        })
      }
    },

    async deleteAddon(addon) {
      try {
        await th.productAddons().delete(addon.id)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.forms.error.delete_fail.message', {
            resource: this.$t('common.resource.product_addon.singular')
          })
        })
      }
    },

    async fetch() {
      try {
        this.loading = true
        const { data = {} } = await th
          .productAddonGroups()
          .get(this.addonGroupId)
        if (data.id) {
          this.handleItem(data)
          await this.fetchAddons()
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.product_addon_groups.edit.messages.fetch_error'
          )
        })
      } finally {
        this.loading = false
      }
    },

    handleItem(item) {
      this.payload = { ...item }
      this.payload.addons = []
      const cleanedPayload = pick(item, Object.keys(this.form))

      this.form = {
        ...this.form,
        ...cleanedPayload
      }
    },

    async submitForm() {
      const valid = await this.$refs.general.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          title: this.$t('common.forms.warning.invalid_input.title'),
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      if (this.isNew) return this.create()
      this.alter(this.payload.id)
    },

    async handleDelete() {
      const confirm = await this.$askToDelete(this.form.name)
      if (confirm) this.delete()
    },

    async delete() {
      try {
        await th.productAddonGroups().delete(this.addonGroupId)

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.delete.single', {
            resource: this.$t('common.resource.product_addon_group.singular')
          })
        })

        this.$router.push({ name: 'products-addon-groups-list' })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.product_addon_group.singular')
          })
        })
      }
    },

    async alter() {
      const payload = {
        ...this.form
      }
      delete payload.addons

      try {
        this.loading = true

        const { data = {} } = await th
          .productAddonGroups()
          .put(this.addonGroupId, payload)

        if (data.id) {
          this.handleItem(data)
          await this.handleAddons(data.id)
        }

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.product_addon_group.singular')
          })
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.product_addon_group.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    async create() {
      const payload = {
        ...this.form
      }
      delete payload.addons

      try {
        this.loading = true
        const { data = {} } = await th
          .productAddonGroups()
          .create(makeHandleableBody(payload, this.form))

        if (data.id) {
          this.handleItem(data)
          await this.handleAddons(data.id)
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.product_addon_group.singular')
            })
          })

          this.$router.push({
            name: 'products-addon-groups-edit',
            params: { id: data.id }
          })
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.product_addon_group.singular')
          })
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
